<template>
  <dv-full-screen-container class="bj">
    <dv-border-box-8 style="background: rgba(22, 40, 80, 0.8)">
      <van-overlay :show="showoverlay" @click="showoverlay = false">
        <dv-loading>
          <div style="color: #0dc4f4">加载中...</div>
        </dv-loading>
      </van-overlay>

      <van-popup v-model:show="popupshow" round>
        <dv-border-box-1 style="">
          <div class="popup">
            <div class="popuptitle">是否允许播放声音？</div>
            <div class="popupfooter">
              <div @click="audio">拒绝</div>
              <div @click="audio">允许</div>
            </div>
          </div>
        </dv-border-box-1>
      </van-popup>

      <!-- 出入库 -->
      <van-popup
        v-model:show="entershow"
        closeable
        :close-on-click-overlay="false"
        :style="{ height: '65%', width: '35%', border_radius: '5px' }"
      >
        <dv-border-box-13>
          <div class="entershow">
            <div class="entershowtitle">入库对账{{ xin.code }}</div>
            <div>
              <van-field
                v-model="entersum"
                type="number"
                disabled
                placeholder="123"
              >
                <template #label>
                  <div style="color: #fff">总数</div>
                </template>
              </van-field>
              <div v-for="(item, index) in from" :key="index">
                <van-field
                  v-model="value[index]"
                  type="number"
                  @blur="blur"
                  readonly
                  @focus="focus(index)"
                  @touchstart.stop="keyboard_show = true"
                >
                  <template #label>
                    <div style="color: #fff">{{ item.name }}</div>
                  </template>
                  <template #extra>
                    <div style="color: #fff">读卡数 {{ item.count }}</div>
                  </template>
                </van-field>
                <van-number-keyboard
                  class="key"
                  :maxlength="3"
                  v-model="value[focusid]"
                  :show="keyboard_show"
                  @blur="keyboard_show = false"
                  @input="onInput"
                  @delete="onDelete"
                />
              </div>
            </div>
            <van-botton @click="enters" type="default" class="but"
              >确认入库</van-botton
            >
          </div>
        </dv-border-box-13>
      </van-popup>
      <van-popup
        v-model:show="outshow"
        closeable
        :close-on-click-overlay="false"
        :style="{ height: '65%', width: '35%' }"
      >
        <dv-border-box-13>
          <div class="outshow">
            <div class="entershowtitle">出库对账{{ xin.code }}</div>
            <div>
              <van-field
                v-model="outsum"
                type="number"
                disabled
                placeholder="0"
              >
                <template #label>
                  <div style="color: #fff">总数</div>
                </template>
              </van-field>
              <div v-for="(item, index) in from" :key="index">
                <van-field
                  v-model="outvalue[index]"
                  type="number"
                  @blur="outblur"
                  @focus="focus(index)"
                  readonly
                  @touchstart.stop="keyboard_show = true"
                >
                  <template #label>
                    <div style="color: #fff">{{ item.name }}</div>
                  </template>
                  <template #extra>
                    <div style="color: #fff">读卡数 {{ item.count }}</div>
                  </template>
                </van-field>
                <van-number-keyboard
                  class="key"
                  v-model="outvalue[focusid]"
                  :show="keyboard_show"
                  @blur="keyboard_show = false"
                  @input="onInput"
                  @delete="onDelete"
                />
              </div>
            </div>
            <van-botton @click="outs" type="default" class="but"
              >确认出库</van-botton
            >
          </div>
        </dv-border-box-13>
      </van-popup>
      <van-row type="flex" style="height: 100%">
        <van-col span="24" style="height: 10%; display: flex; margin: 5px">
          <div class="info">
            <img :src="info?.avatar" class="logo" v-if="info?.avatar" />
            <img
              src="../assets/slogan.png"
              class="logos"
              v-if="!info?.avatar"
            />
            <div v-if="device?.hotel_name">
              {{ device?.hotel_name }}
            </div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任酒店卫生联防联控云平台</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device?.model">设备型号：{{ device?.model }}</div>
            <div v-if="device?.id">设备编号：{{ device?.id }}</div>
            <div v-if="state" style="color: #00ff89"><span>●</span>在线</div>
            <div v-if="!state" style="color: #808080"><span>●</span>离线</div>
          </div>
        </van-col>
        <van-col span="14" :style="{ height: height }">
          <div class="flex">
            <dv-border-box-5 class="tag" :color="['#435FA5', '#34A8CD']">
              <div class="tags">
                <div v-if="xin?.status == 0" style="color: #0781b3">
                  <div style="display: flex; align-items: center">
                    <van-loading
                      size="24px"
                      v-if="xin?.status == 0"
                      color="#0781B3"
                    />
                    正在读卡中
                  </div>
                </div>
                <div
                  style="display: flex; align-items: center; color: #0781b3"
                  v-if="
                    door_scene && door_scene != 'kong' && door_scene == 'come'
                  "
                >
                  已入库
                </div>
                <div
                  style="display: flex; align-items: center; color: #0781b3"
                  v-if="
                    door_scene && door_scene != 'kong' && door_scene == 'wash'
                  "
                >
                  已出库
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                  "
                >
                  <div style="color: #068cc1">当前读卡数量</div>
                  <div style="color: #fff" v-if="xin?.dates">
                    {{ xin?.dates }}
                  </div>
                  <div style="color: #068cc1" v-if="!xin?.date">
                    {{ date }}
                  </div>
                  <div class="beizhu" v-if="xin?.code">
                    <div>批次号:{{ xin?.code }}</div>
                  </div>
                </div>
              </div>
            </dv-border-box-5>
            <dv-border-box-6
              :color="['#435FA5']"
              style="padding: 10px; width: 50%; height: 80%"
            >
              <div v-if="order_feed.length > 0">
                <div style="font-size: 30px; color: #fff">订单详情</div>
                <div style="font-size: 20px; color: #fff; padding: 5px 0">
                  订单号：{{ order_feed[0].code }}
                </div>
                <div
                  style="
                    color: #40c5ff;
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                  "
                >
                  <div
                    v-for="e in order_feed[0].bucaos"
                    :key="e"
                    style="
                      border: 1px solid #3d5aa1;
                      width: 32.9%;
                      padding: 30px 0;
                    "
                  >
                    <div style="font-size: 30px">{{ e.count }}</div>
                    <div style="">{{ e.name }}</div>
                  </div>
                </div>
              </div>
            </dv-border-box-6>
            <dv-decoration-9 style="width: 40%" :dur="dur">
              <div :class="xin.count ? 'titles' : 'zero'">
                <countTo
                  :startVal="uncount"
                  :endVal="xin?.count || 0"
                  :duration="3000"
                ></countTo>
              </div>
            </dv-decoration-9>
          </div>
          <dv-decoration-3
            style="width: 250px; height: 30px; margin: 30px auto"
          />
          <div class="anniu">
            <dv-border-box-12
              class="annius"
              @click="enter"
              v-if="xin?.status == 1 && door_scene == 'kong'"
              >入库</dv-border-box-12
            >
            <dv-border-box-12
              @click="coloses"
              class="annius"
              style="color: #fff"
              v-if="xin?.status == 0"
              >归零</dv-border-box-12
            >
            <dv-border-box-12
              @click="close"
              class="annius"
              style="color: #fff"
              v-if="xin?.status == 0"
              >结束</dv-border-box-12
            >
            <dv-border-box-12
              class="annius"
              @click="out"
              v-if="xin?.status == 1 && door_scene == 'kong'"
              >出库</dv-border-box-12
            >
          </div>
        </van-col>
        <Video :src="videosrc" v-show="videoshow" @videoshow="videoshows">
          <template v-slot:title>{{ title }} 摄像机</template>
          <template v-slot:footer>
            <!-- <van-swipe class="swipes" @change="onChange">
          <van-swipe-item v-for="(item, index) in list" :key="index">{{
            item.name
          }}</van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">
              {{ actives + 1 }}/{{ list.length }}
            </div>
          </template>
        </van-swipe> -->
          </template>
        </Video>
        <van-col span="10" :style="{ height: height }">
          <div class="times">
            <dv-border-box-8
              :reverse="true"
              class="back"
              @click="back"
              v-if="backshow"
              >返回详情</dv-border-box-8
            >
            <!-- <div  >返回详情</div> -->
            <dv-border-box-13 class="btn">
              <van-tabs
                v-model:active="active"
                @click="ontab"
                color="#1195F8"
                background="rgba(0,0,0,0)"
              >
                <van-tab
                  ><template #title>
                    <span class="spancolor">详情</span>
                  </template>
                  <div class="border" :style="{ height: listheight }">
                    <div
                      class="app-card-content"
                      v-if="assetsdetailList.length < 1"
                    >
                      <div
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="['app-card-content_item']"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">0</div>
                            <div class="box_name">被套</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                      <div
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="['app-card-content_item']"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">0</div>
                            <div class="box_name">床单</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                      <div
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="['app-card-content_item']"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">0</div>
                            <div class="box_name">枕套</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                      <div
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="['app-card-content_item']"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">0</div>
                            <div class="box_name">浴巾</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                      <div
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="['app-card-content_item']"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">0</div>
                            <div class="box_name">面巾</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                      <div
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="['app-card-content_item']"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">0</div>
                            <div class="box_name">地巾</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                    </div>
                    <div
                      class="app-card-content"
                      v-if="assetsdetailList.length > 0"
                    >
                      <div
                        v-for="(item, index) in assetsdetailList"
                        :key="index"
                        :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                        :class="[
                          'app-card-content_item',
                          getBorderClass(index),
                        ]"
                      >
                        <dv-border-box-7 :color="['#3D5AA1']">
                          <div class="fenlei">
                            <div class="box">
                              <countTo
                                :startVal="item?.uncount || 0"
                                :endVal="item?.count || 0"
                                :duration="3000"
                              ></countTo>
                              <span
                                v-if="
                                  dorr_count !== 'kong' &&
                                  dorr_count &&
                                  item.count != dorr_count[index]?.count
                                "
                                style="font-size: 20px; color: red"
                                >/</span
                              >
                              <countTo
                                :startVal="0"
                                :duration="3000"
                                :endVal="dorr_count[index]?.count"
                                v-if="
                                  dorr_count !== 'kong' &&
                                  dorr_count &&
                                  item.count != dorr_count[index]?.count
                                "
                                style="font-size: 20px; color: red"
                              ></countTo>
                            </div>
                            <div class="box_name">{{ item.name }}</div>
                          </div>
                        </dv-border-box-7>
                      </div>
                    </div>
                  </div>
                </van-tab>
                <van-tab
                  ><template #title>
                    <span class="spancolor">明细</span>
                  </template>
                  <van-pull-refresh
                    v-model="isLoading"
                    @refresh="onRefreshrfid"
                    success-text="刷新成功"
                    class="height"
                    :style="{ height: listheight }"
                  >
                    <div v-if="rfidList.length < 1" class="no">暂无</div>
                    <van-list
                      v-model="loadingrfid"
                      loading-text="加载中..."
                      :finished="finishedrfid"
                      finished-text="没有更多了"
                      @load="onrfid"
                      v-if="rfidList.length > 0"
                    >
                      <van-cell-group
                        v-for="(item, index) in rfidList"
                        :key="item"
                        ><van-cell @click="onchipList(index)">
                          <template #title>
                            <div
                              class="serviceList_chips"
                              style="color: #0499d1"
                            >
                              <div style="text-align: left">
                                {{ item.bucao_model || "暂无" }}
                              </div>
                              <div
                                class="serviceList_chip"
                                style="color: #0499d1"
                              >
                                {{ item.rfid || "暂无" }}
                              </div>
                            </div>
                          </template>
                          <template #default>
                            <div class="default_chip" style="color: #0499d1">
                              <div>读卡时间:{{ item.time || "暂无" }}</div>
                              <div>
                                洗涤时间:{{ item.wash_start_time || "暂无" }}
                              </div>
                            </div>
                          </template>
                          <template #label>
                            <dv-decoration-2
                              style="width: 100%; height: 2px"
                              :dur="2"
                            />
                          </template>
                        </van-cell>
                      </van-cell-group> </van-list
                  ></van-pull-refresh>
                </van-tab>
                <van-tab
                  ><template #title>
                    <span class="spancolor">当日</span>
                    <div class="date">
                      {{ date }}
                    </div>
                  </template>
                  <van-pull-refresh
                    v-model="isLoading"
                    @refresh="onRefreshserviceList"
                    success-text="刷新成功"
                    class="height"
                    :style="{ height: listheight }"
                  >
                    <div v-if="serviceList.length < 1" class="no">暂无</div>
                    <van-list
                      v-model="loading"
                      :finished="finished"
                      finished-text="没有更多了"
                      @load="onLoad"
                      v-if="serviceList.length > 0"
                    >
                      <van-cell-group
                        v-for="(item, index) in serviceList"
                        :key="item"
                      >
                        <van-cell @click="onserviceList(index)" is-link center
                          ><template #icon>
                            <dv-border-box-7 :color="['#3D5AA1']" class="imgss">
                              <div class="imgss">
                                <van-image
                                  class="img"
                                  :src="item.cctv_snapshot"
                                  @click="onserviceListvideo(index)"
                                  v-if="item.cctv_snapshot"
                                >
                                </van-image>
                                <img
                                  src="../assets/video.png"
                                  alt=""
                                  v-if="!item.cctv_snapshot"
                                  style="width: 100%"
                                />
                              </div>
                            </dv-border-box-7>
                          </template>
                          <template #title>
                            <div class="serviceLists" style="color: #0499d1">
                              <div style="display: flex">
                                <van-loading
                                  size="24px"
                                  v-if="item.status == 0"
                                  color="#0781B3"
                                />
                                <div
                                  :class="{
                                    color:
                                      item.asset_scene == '资产移动' ? 0 : 1,
                                  }"
                                >
                                  {{ item.asset_scene }}
                                </div>
                              </div>
                              <div
                                :class="{
                                  color: item.asset_scene == '资产移动' ? 0 : 1,
                                }"
                              >
                                {{ item.count }}
                              </div>
                            </div>
                          </template>
                          <template #default>
                            <div
                              class="default"
                              style="color: #0499d1"
                              :class="{
                                color: item.asset_scene == '资产移动' ? 0 : 1,
                              }"
                            >
                              {{ item.minute }}
                            </div>
                          </template>
                        </van-cell>
                      </van-cell-group>
                    </van-list></van-pull-refresh
                  >
                </van-tab>
                <van-tab
                  ><template #title>
                    <span class="spancolor">历史</span>
                  </template>
                  <van-pull-refresh
                    v-model="isLoading"
                    @refresh="onRefresh"
                    success-text="刷新成功"
                    class="height"
                    :style="{ height: listheight }"
                  >
                    <div v-if="assetdateList.length < 1" class="no">暂无</div>
                    <van-list
                      v-model="loading"
                      :finished="finished"
                      finished-text="没有更多了"
                      @load="onLoad"
                      v-if="assetdateList.length > 0"
                    >
                      <van-cell-group
                        v-for="(item, index) in assetdateList"
                        :key="item"
                        ><van-cell @click="onassetdateList(index)" is-link>
                          <template #title>
                            <div class="serviceList" style="color: #0499d1">
                              <div>{{ item.date }}</div>
                              <div>({{ item.week }})</div>
                            </div>
                          </template>
                          <template #default>
                            <div class="default" style="color: #0499d1">
                              {{ item.count_service }}
                            </div>
                          </template>
                          <template #label>
                            <dv-decoration-2
                              style="width: 100%; height: 2px"
                              :dur="2"
                            />
                          </template>
                        </van-cell>
                      </van-cell-group> </van-list
                  ></van-pull-refresh>
                </van-tab>
              </van-tabs>
            </dv-border-box-13>
          </div>
        </van-col>
      </van-row>
    </dv-border-box-8>
  </dv-full-screen-container>
</template>

<script>
import {
  Col,
  Row,
  Tab,
  Tabs,
  Icon,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  Button,
  Card,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Notify,
  Overlay,
  Loading,
  CountDown,
  Field,
  Popover,
  NumberKeyboard,
} from "vant";
import api from "../utils/api";
import mqtt from "mqtt";
import countTo from "./vue-countTo.vue";
import Video from "./video.vue";
import store from "../store/index";
export default {
  name: "home",
  props: {},
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Card.name]: Card,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [CountDown.name]: CountDown,
    [Field.name]: Field,
    [Popover.name]: Popover,
    [NumberKeyboard.name]: NumberKeyboard,
    Video,
    countTo,
  },
  data() {
    return {
      num: "",
      intervalId: null,
      intervalIds: null,
      active: 0,
      actives: 0,
      lineNumber: 3,
      info: {},
      xin: {},
      uncount: 0,
      classcount: 0,
      date: "",
      mtopic: "",
      mtopics: "",
      mtopicfeed: "",
      msg: "",
      heart: "",
      datestr: "",
      state: false,
      service: "",
      client: {},
      hotel_code: "",
      device_code: "",
      device: {},
      list: [{ name: 1 }, { name: 2 }, { name: 3 }, { name: 4 }],
      serviceList: [],
      assetdateList: [],
      assetsdetailList: [],
      rfidList: [],
      loading: false,
      isLoading: false,
      finished: false,
      loadingrfid: false,
      finishedrfid: false,
      height: document.body.scrollHeight + "px",
      listheight: 3.8 + "rem",
      show: false,
      videoshow: false,
      videosrc: "",
      title: "",
      size: 20,
      showoverlay: false,
      backshow: false,
      backtime: 120000,
      datatime: 5000,
      datatimes: 20000,
      oncount: 0,
      isLoaded: false,
      popupshow: false,
      downloadurl: "",
      entershow: false,
      outshow: false,
      door_scene: "kong",
      value: [],
      outvalue: [],
      entersum: "",
      outsum: "",
      leaser: [],
      factory: [],
      keyboard_show: false,
      keyboard_v: "",
      dorr_count: "",
      focusid: "",
      dur: 0,
      order_feed: [],
      bucaos: [
        {
          code: "cd",
          count: 0,
          name: "床单",
          sort: 1,
        },
        {
          code: "bt",
          count: 0,
          name: "被套",
          sort: 2,
        },
        {
          code: "zt",
          count: 0,
          name: "枕套",
          sort: 3,
        },
        {
          code: "yj",
          count: 0,
          name: "浴巾",
          sort: 11,
        },
        {
          code: "mj",
          count: 0,
          name: "面巾",
          sort: 12,
        },
        {
          code: "dj",
          count: 0,
          name: "地巾",
          sort: 13,
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.renderResize, false);
    this.num = store.state.edition;
    const url = location.hash;
    console.log("url", url);
    if (url && url.indexOf("?") != -1) {
      localStorage.setItem("device_code", url.split("=")[1]);
      this.device_code = localStorage.getItem("device_code");
      // this.getdevice();
      this.devicefetch();
      this.popupshow = true;
    } else {
      Notify({ message: "请输入设备编码", duration: 50000 });
    }
  },
  created() {
    console.log("created");
    this.apptonelist();
    this.dataRefreh();
  },
  unmounted() {
    // 在页面销毁后，清除计时器
    this.clear();
    this.clears();
  },
  watch: {
    service: function (newVal, endVal) {
      if (endVal && endVal != newVal) {
        console.log("新批次service", newVal, endVal);
      }
    },
    serviceList: function (newVal, endVal) {
      if (endVal && endVal.length != newVal.length) {
        console.log("新批次serviceList", newVal, endVal);
      }
    },
    xin: function (newVal, endVal) {
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          // console.log("xin", endVal.count, "xin.count" + newVal.count);
          this.uncount = endVal.count;
        } else {
          // console.log("xin0", endVal.count, "xin.count" + newVal.count);
          this.uncount == 0;
        }
      }
    },
    msg: function (newVal, endVal) {
      // console.log("magwatch", endVal, newVal);
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal.count;
          // console.log("endVals", newVal, endVal);
          if (newVal.records) {
            newVal = newVal.records.map((i, e) => {
              i.uncount = endVal.records[e].count;
            });
            // console.log("endVal", newVal, endVal);
          }
        } else {
          this.uncount == 0;
          newVal = newVal.records.map((i) => {
            i.uncount = 0;
          });
        }
      }
    },
  },
  methods: {
    audio() {
      console.log("允许");
      this.popupshow = false;
      const audio = new Audio();
      // audio.src = `file:///C:/Users/reign770/Downloads+${this.downloadurl}`;
      audio.src = "http://d.singran.vip/assets/audio/di.mp3";
      audio.play();
    },
    mqtt() {
      this.client = mqtt.connect("ws://mqtt.singran.vip:8083/mqtt", {
        clientId: "",
        clean: true,
        reconnect: true,
        keepAliveInterval: 30,
        reconnectInterval: 10,
        username: "xinren_factory",
        password: "xinren20200807",
      });
      // 断开发起重连
      this.client.on("reconnect", (error) => {
        console.log("正在重连:", error);
      });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
      });
      this.client.on("connect", (e) => {
        console.log("连接成功", e);
        this.client.subscribe(this.mtopic, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopic);
          }
        });
        this.client.subscribe(this.mtopics, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopics);
          }
        });
        this.client.subscribe(this.mtopicfeed, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopicfeed);
          }
        });
        this.client.on("message", (topic, message) => {
          if (JSON.parse(String(message)).heart_ts) {
            this.heart = JSON.parse(String(message));
            this.heart_ts = this.heart.heart_ts || 0;
          } else {
            this.msg = JSON.parse(String(message));
          }
          const apptonelist = JSON.parse(localStorage.getItem("appToneList"));
          console.log("msg", this.msg, topic, "heart", this.heart);
          if (topic.indexOf("order_feed") != -1) {
            console.log(
              "order_feed========================================",
              this.msg
            );
            if (this.msg[0].bucaos.length < 6) {
              this.msg[0].bucaos.map((e) => {
                this.bucaos.map((item) => {
                  if (item.code == e.code) {
                    item.count = e.count;
                  }
                });
              });
              this.msg[0].bucaos = this.bucaos;
            }
            this.order_feed = this.msg;
          }
          if (this.msg.status == 0 && this.msg?.ts) {
            console.log("进行中", "初始" + this.uncount);
            this.dur = 3;
            this.clears();
            this.backRefreh();
            this.xin.count = this.msg.count;
            this.xin.code = this.msg.service;
            this.xin.status = this.msg.status;
            this.assetsdetailList = this.msg.records;
            if (this.isLoaded) {
              return;
            }
            this.isLoaded = true;
            const audio = new Audio();
            audio.src = apptonelist[0].file_path;
            audio.play();
          } else {
            if (this.msg?.ts) {
              this.dur = 0;
              this.xin.count = this.msg.count;
              this.xin.code = this.msg.service;
              this.xin.status = this.msg.status;
              this.assetsdetailList = this.msg.records;
              console.log("已关闭", "初始" + this.uncount);
              // const audio = new Audio();
              // audio.src = apptonelist[1].file_path;
              // audio.play();
              this.isLoaded = false;
              // this.clears();
              // this.backRefreh();
            }
          }
        });
      });
    },
    apptonelist() {
      const data = {
        app_name: "hasn",
      };
      api.apptonelist(data).then((res) => {
        console.log("语音", res.data.data);
        localStorage.setItem(
          "appToneList",
          JSON.stringify(res.data.data?.appToneList)
        );
        // this.download();
      });
    },
    download() {
      const apptonelist = JSON.parse(localStorage.getItem("appToneList"));
      var a = document.createElement("a");
      a.href = apptonelist[0].file_path;
      a.download = "audio.wav";
      if (a.href) {
        a.click();
      }
      this.downloadurl = apptonelist[0].file_path.split("/")[7];
      console.log("a标签", apptonelist[0].file_path.split("/")[7], a);
    },
    backRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalIds) {
        clearInterval(this.intervalIds);
      } else {
        console.log(`打开定时器${this.backtime}ms 归零`);
        this.intervalIds = setInterval(() => {
          this.coloses();
          this.clears();
        }, this.backtime);
      }
    },
    // 定时刷新数据函数
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.log(`打开定时器${this.datatime}`);
        this.intervalId = setInterval(() => {
          this.onserviceList(0, "type");
          this.states(new Date().getTime(), this.heart?.heart_ts);
        }, this.datatime);
      }
    },
    dataRefrehs() {
      // 计时器正在进行中，退出函数
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.log(`打开定时器${this.datatimes}`);
        this.intervalId = setInterval(() => {
          this.onserviceList(this.oncount, "type");
        }, this.datatimes);
      }
    },
    // 停止定时器
    clear() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    clears() {
      clearInterval(this.intervalIds); //清除计时器
      this.intervalIds = null; //设置为null
    },
    videoshows() {
      this.videoshow = !this.videoshow;
    },
    showPopup() {
      console.log(this.show);
      this.show = !this.show;
    },
    devicefetch() {
      api.devicefetch({ device_code: this.device_code }).then((res) => {
        this.showoverlay = res.loading;
        console.log("设备", res);
        this.device = res.data.data.device;
        this.heart_ts = res.data.data.device.last_heart_ts;
        document.title = `芯任酒店卫生联防联控云平台_${this.device?.hotel_name}`;
        this.info = res.data.data.hotel;
        this.device_code =
          res.data.data?.device?.lctf || res.data.data?.dpod?.lctf;
        console.log(this.device_code);
        this.mtopic = "device/" + this.device_code + "/service";
        this.mtopics = "device/" + this.device_code + "/heart";
        this.mtopicfeed = "device/" + this.device_code + "/order_feed";
        this.mqtt();
        this.time();
        this.hotelassetsquery();
        this.hotelassetdate();
        this.hotelfactory();
        this.hotelleaser();
      });
    },
    getdevice() {
      const data = {
        device_code: this.device_code,
      };
      this.showoverlay = true;
      api.hoteldevice(data).then((res) => {
        this.showoverlay = res.loading;
        console.log("设备", res);
        this.device = res.data.data.device;
        this.heart_ts = res.data.data.device.last_heart_ts;
        document.title = `芯任酒店卫生联防联控云平台_${this.device?.hotel_name}`;
        this.info = res.data.data.hotel;
        this.mtopic = "device/" + this.device_code + "/service";
        this.mtopics = "device/" + this.device_code + "/heart";
        this.mtopicfeed = "device/" + this.device_code + "/order_feed";
        this.mqtt();
        this.time();
        this.hotelassetsquery();
        this.hotelassetdate();
        this.hotelfactory();
        this.hotelleaser();
      });
    },
    timess(e) {
      let time = new Date(e);
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      let hour = time.getHours();
      let minute = time.getMinutes();
      let second = time.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (date >= 0 && date <= 9) {
        date = "0" + date;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minute >= 0 && minute <= 9) {
        minute = "0" + minute;
      }
      if (second >= 0 && second <= 9) {
        second = "0" + second;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    states(e) {
      // console.log(e, this.heart_ts);
      let a;
      if (e > this.heart_ts) {
        a = e - this.heart_ts;
      } else {
        a = this.heart_ts - e;
      }
      if (a / (1000 * 60) > 2) {
        this.state = false;
      } else {
        this.state = true;
      }
      // console.log(a / (1000 * 60), this.state);
    },
    time() {
      const date = new Date();
      this.datestr = date.getTime();
      this.states(this.datestr);
      let seperator1 = "-";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let currentdate = year + seperator1 + month + seperator1 + strDate;
      this.date = currentdate;
    },
    hotelassetdate() {
      const data = {
        device_code: this.device_code,
      };
      this.showoverlay = true;
      api.hotelassetdate(data).then((res) => {
        this.showoverlay = res.loading;
        this.assetdateList = res.data.data.dates;
        console.log("历史追溯", res.data.data);
      });
    },
    hotelassetsquery() {
      const data = {
        device_code: this.device_code,
        date: this.date,
      };
      this.showoverlay = true;
      api.hotelassetsquery(data).then((res) => {
        this.showoverlay = res.loading;
        console.log("当日追踪", res.data.data);
        if (res.data.data.services.length > 0) {
          res.data.data.services.map((i) => {
            const date = i.minute;
            i.minute = date.split(" ")[1];
            i.date = date.split(" ")[0];
            i.dates = this.timess(i.ts);
            if (i.asset_scene === 0) {
              i.asset_scene = "资产领用";
            } else if (i.asset_scene == 1) {
              i.asset_scene = "送洗归库";
            } else if (i.asset_scene == 2) {
              i.asset_scene = "租赁入库";
            } else if (i.asset_scene == 3) {
              i.asset_scene = "资产报损";
            } else if (i.asset_scene == 4) {
              i.asset_scene = "返洗出库";
            } else {
              i.asset_scene = "资产移动";
            }
          });
        }
        this.serviceList = res.data.data.services;
        this.onserviceList(0, "type");
      });
    },
    hotelassetsdetail(e, i) {
      let data;
      if (e) {
        data = {
          service: e,
        };
      } else {
        data = {
          service: this.service,
        };
      }
      this.showoverlay = true;
      api.hotelassetsdetail(data).then((res) => {
        console.log("资产详情", res);
        this.showoverlay = res.loading;
        if (res.data.data?.records) {
          this.assetsdetailList = res.data.data?.records;
          this.from = res.data.data?.records;
          this.value = [];
          this.outvalue = [];
          this.from.map((i) => {
            this.value.push(i.count);
            this.outvalue.push(i.count);
          });
          if (i == "chu") {
            this.outsum = this.outvalue.reduce((i, e) => {
              return Number(i) + Number(e);
            });
          } else if (i == "ru") {
            this.entersum = this.value.reduce((i, e) => {
              return Number(i) + Number(e);
            });
          }
          console.log(this.value, this.outvalue);
        }
        console.log(res.data.data.service.door_scene);
        if (res.data.data.service.door_scene) {
          console.log("you");
          this.door_scene = String(res.data.data?.service?.door_scene);
          const service = res.data.data?.service;
          this.dorr_count = [
            {
              code: "cd",
              count: service.door_count_cd,
            },
            {
              code: "bt",
              count: service.door_count_bt,
            },
            {
              code: "zt",
              count: service.door_count_zt,
            },
            {
              code: "yj",
              count: service.door_count_yj,
            },
            {
              code: "mj",
              count: service.door_count_mj,
            },
            {
              code: "dj",
              count: service.door_count_dj,
            },
            {
              code: "yp",
              count: service.door_count_yp,
            },
            {
              code: "fj",
              count: service.door_count_fj,
            },
          ];
        } else {
          this.door_scene = "kong";
          this.dorr_count = "kong";
        }
      });
    },
    hotelassetsrfiddetail() {
      const data = {
        service: this.service,
        offset: 0,
        size: this.size,
      };
      this.showoverlay = true;
      api.hotelassetsrfiddetail(data).then((res) => {
        this.showoverlay = res.loading;
        console.log("芯片详情", res.data.data);
        if (res.data.data?.records) {
          res.data.data.records = this.times(res.data.data?.records);
          this.rfidList = res.data.data.records;
        }
      });
    },
    onassetdateList(i) {
      this.date = this.assetdateList[i].date;
      this.serviceList = [];
      this.clear();
      this.dataRefrehs();
      this.hotelassetsquery();
      this.ontab(2);
    },
    onserviceList(i, e) {
      if (e) {
        this.service = this.serviceList[i]?.code;
      } else {
        this.oncount = i;
        this.clear();
        this.clears();
        this.dataRefrehs();
        this.backRefreh();
        this.size = 20;
        this.finishedrfid = false;
        this.xin = this.serviceList[this.oncount];
        this.assetsdetailList = [];
        this.service = this.serviceList[this.oncount].code;
        this.hotelassetsdetail();
        this.ontab(0);
      }
    },
    onserviceListvideo(i) {
      if (this.serviceList[i].cctv_id) {
        this.videohttp(this.serviceList[i].cctv_id);
        this.title =
          this.serviceList[i].date + " " + this.serviceList[i].minute;
      } else {
        Notify({
          message: "当前批次无视频记录",
          duration: 1000,
        });
      }
    },
    videohttp(e) {
      const data = {
        id: e,
      };

      api.videohttps(data).then((res) => {
        console.log("视频链接", res);
        this.videosrc = res.data.data?.videoUrl;
        console.log(this.videosrc);
        if (this.videosrc) {
          this.videoshow = !this.videoshow;
        } else {
          Notify({
            message: "视频连接超时请重试",
            duration: 1000,
          });
        }
      });
    },
    onchipList(i) {
      console.log(i);
    },
    onChange(e) {
      console.log(e);
      this.actives = e;
    },
    getBorderClass(index) {
      index += 1;
      let className = "";
      let lineNumber = this.lineNumber; // 每行行显示个数
      let total = this.assetsdetailList.length; // 总个数
      let line = Math.ceil(total / lineNumber); // 一共有几行
      let currentLine = 0; // 当前在第几行,这里从1开始
      let currentIndex = 0; // 当前在某行的第几个
      currentLine = Math.ceil(index / lineNumber);
      currentIndex = index % lineNumber === 0 ? lineNumber : index % lineNumber;
      // 右下边框
      if (currentLine < line && currentIndex < lineNumber) {
        className = "border-bottom border-right";
      }
      // 右边框
      if (currentLine === line && currentIndex < lineNumber) {
        className = "border-right";
      }
      // 下边框
      if (currentLine < line && currentIndex === lineNumber) {
        className = "border-bottom";
      }
      // 无边框
      if (currentLine === line && currentIndex === lineNumber) {
        className = "";
      }
      return className;
    },
    renderResize() {
      this.height = document.body.clientHeight + "px";
      this.listheight = 3.8 + "rem";
    },
    ontab(e) {
      if (e != 0) {
        this.backshow = true;
      } else {
        this.backshow = false;
      }
      if (e == 1) {
        this.rfidList = [];
        this.hotelassetsrfiddetail();
      } else if (e == 2) {
        this.serviceList = [];
        this.hotelassetsquery();
      } else if (e == 3) {
        this.assetdateList = [];
        this.hotelassetdate();
      }
      this.active = e;
    },
    onRefreshserviceList() {
      setTimeout(() => {
        this.hotelassetsquery();
        this.isLoading = false;
      }, 1000);
    },
    onRefreshrfid() {
      setTimeout(() => {
        this.hotelassetsrfiddetail();
        this.isLoading = false;
      }, 1000);
    },
    onRefresh() {
      setTimeout(() => {
        this.hotelassetdate();
        this.isLoading = false;
      }, 1000);
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // 加载状态结束
        this.loading = false; // 数据全部加载完成
        if (this.serviceList.length >= this.serviceList.length) {
          this.finished = true;
        }
      }, 1000);
    },
    onrfid() {
      this.size += 20;
      const data = {
        service: this.service,
        offset: 0,
        size: this.size,
      };
      this.loadingrfid = true;
      this.showoverlay = true;
      api.hotelassetsrfiddetail(data).then((res) => {
        this.showoverlay = res.loading;
        this.loadingrfid = false;
        console.log("芯片详情", res.data.data);
        if (res.data.data.records) {
          res.data.data.records = this.times(res.data.data.records);
        }
        this.rfidList = res.data.data?.records;
        console.log(this.size, this.xin.count);
        if (this.size > this.xin.count) {
          this.finishedrfid = true;
        }
      });
    },
    times(e) {
      if (e && e[0].time.split(" ").length > 1) {
        e.map((i) => {
          if (i.time) {
            i.time =
              i.time.split(" ")[1].split(":")[0] +
              ":" +
              i.time.split(" ")[1].split(":")[1];
          }
          if (i.wash_start_time) {
            i.wash_start_time =
              i.wash_start_time.split("-")[1] +
              "-" +
              i.wash_start_time.split("-")[2];
          }
        });
      }
      return e;
    },
    back() {
      console.log("返回");
      this.time();
      this.hotelassetsquery();
      this.hotelassetdate();
      this.ontab(0);
    },
    zero() {
      console.log("归零");
      this.assetsdetailList = [];
      this.xin = [];
      this.order_feed = [];
      this.msg = {};
      this.uncount = 0;
      this.ontab(0);
      this.clear();
      this.dataRefreh();
    },
    out() {
      console.log("出库");
      this.outshow = true;
      this.hotelassetsdetail(this.xin.code, "chu");
      // this.outsum = this.outvalue.reduce((i, e) => {
      //   return Number(i) + Number(e);
      // });
      console.log("chu", this.outsum);
    },
    outblur() {
      this.outsum = this.outvalue.reduce((i, e) => {
        return Number(i) + Number(e);
      });
    },
    enter() {
      console.log("入库");
      this.entershow = true;
      this.hotelassetsdetail(this.xin.code, "ru");
      // this.entersum = this.value.reduce((i, e) => {
      //   return Number(i) + Number(e);
      // });
      console.log("ru", this.entersum);
    },
    blur() {
      this.entersum = this.value.reduce((i, e) => {
        return Number(i) + Number(e);
      });
    },
    focus(i) {
      // document.activeElement.blur();
      console.log(i);
      this.focusid = i;
    },
    enters() {
      this.value.map((i, e) => {
        this.from[e].count = Number(i);
      });
      const list = this.from.map((i) => {
        return {
          code: i.code,
          count: i.count,
        };
      });
      const data = {
        door_dir: "in",
        door_scene: "come",
        code: this.xin?.code,
        door_detail: list,
        leaser_code: this.leaser[0]?.code,
        factory_code: this.factory[0]?.code,
        remark: "",
      };
      const apptonelist = JSON.parse(localStorage.getItem("appToneList"));
      api.hotelaasetinandout(data).then((res) => {
        console.log("确认入库", res);
        if (res.data.errcode === 0) {
          this.entershow = false;
          const audio = new Audio();
          audio.src = apptonelist[3].file_path;
          audio.play();
        } else {
          Notify({ message: "操作超时", duration: 5000 });
        }
      });
    },
    outs() {
      this.outvalue.map((i, e) => {
        this.from[e].count = Number(i);
      });
      const list = this.from.map((i) => {
        return {
          code: i.code,
          count: i.count,
        };
      });
      const data = {
        door_dir: "out",
        door_scene: "wash",
        code: this.xin?.code,
        door_detail: list,
        leaser_code: this.leaser[0]?.code,
        factory_code: this.factory[0]?.code,
        remark: "",
      };
      const apptonelist = JSON.parse(localStorage.getItem("appToneList"));
      api.hotelaasetinandout(data).then((res) => {
        console.log("确认出库", res);
        if (res.data.errcode === 0) {
          this.outshow = false;
          const audio = new Audio();
          audio.src = apptonelist[4].file_path;
          audio.play();
        } else {
          Notify({ message: "操作超时", duration: 5000 });
        }
      });
    },
    coloses() {
      this.close("延迟");
      this.zero();
    },
    close(e) {
      // debugger // eslint-disable-line no-debugger
      if (e) {
        setTimeout(() => {
          const apptonelist = JSON.parse(localStorage.getItem("appToneList"));
          const audio = new Audio();
          audio.src = apptonelist[2].file_path;
          audio.play();
        }, 5000);
      } else {
        const apptonelist = JSON.parse(localStorage.getItem("appToneList"));
        const audio = new Audio();
        audio.src = apptonelist[2].file_path;
        audio.play();
      }

      console.log("关闭close", this.xin.code);
      this.deskservice("1");
    },
    deskservice(e) {
      console.log(this.xin.code);
      api
        .servicecontrol({ code: this.xin.code, status: e, device_type: "door" })
        .then((res) => {
          console.log("操作", e, this.xin.code, res);
          if (res.data.errcode == 0 && e == 1) {
            Notify({ message: "已关闭", duration: 1000, type: "danger" });
          } else if (res.data.errcode == 0 && e == 2) {
            Notify({ message: "已暂停", duration: 1000, type: "warning" });
          } else if (res.data.errcode == 0 && e == 3) {
            Notify({ message: "已继续", duration: 1000, type: "success" });
          }
        });
    },
    hotelfactory() {
      api.hotelfactory({ hotel_code: this.info.code }).then((res) => {
        console.log("酒店签约", res.data);
        this.factory = res.data.factorys;
      });
    },
    hotelleaser() {
      api.hotelleaser({ hotel_code: this.info.code }).then((res) => {
        console.log("酒店签约", res.data);
        this.leaser = res.data.leasers;
      });
    },
    onInput(key) {
      console.log(key);
    },
    onDelete(e) {
      console.log(e, "123123");
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.van-field__control {
  color: #fff;
}
/deep/.van-popup {
  background: rgba(22, 38, 77, 0);
}
/deep/.van-cell-group {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell {
  background: rgba(0, 0, 0, 0);
  padding: 5px;
}
/deep/[class*="van-hairline"]::after {
  border: none;
}
/deep/.van-tabs__wrap {
  height: 40px;
  margin-top: 3px;
  margin-right: 3px;
}
/deep/.van-tabs__nav--card {
  height: 40px;
  // border-radius: 5px;
  margin: 0;
}
/deep/.van-card {
  background: rgba(12, 24, 51, 0);
  padding: 0;
  margin: 3px;
}
/deep/ .loading {
  width: 20px;
  height: 20px;
}
/deep/.van-tab {
  padding-top: 10px;
}
.bj {
  background-image: url("../assets/bj.jpg");
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
.heradsvg {
  position: absolute;
  width: 60%;
  height: 8%;
  top: 4%;
}
.heard {
  height: 16px;
  width: 16px;
}
.heardtitle {
  font-size: 16px;
  color: #fff;
}
.color {
  color: red;
}
.fenlei {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  font-size: 30px;
  color: #40c5ff;
}
.box_name {
  font-size: 18px;
  color: #4a9cbd;
}
.buts {
  position: absolute;
  top: 5px;
  right: 20%;
  z-index: 999;
  display: flex;
}
.but {
  width: 90px;
  margin: 10px;
  height: 10px;
}
.default {
  font-size: 20px;
}
.serviceLists {
  font-size: 0.2rem;
  text-align: left;
  display: flex;
  justify-content: space-around;
  width: 150px;
}
.serviceList {
  font-size: 0.2rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 150px;
}
.height {
  overflow-y: scroll;
}
.height::-webkit-scrollbar {
  display: none;
}
.flex {
  display: flex;
  align-items: flex-end;
  height: 200px;
  justify-content: space-around;
  margin-top: 30px;
}
.xin {
  width: 80px;
  height: 80px;
}
.logo {
  width: 10px;
  height: 10px;
  background: #fff;
  border: 1px solid #0499d1;
  border-radius: 2px;
}
.logos {
  width: 20px;
  height: 10px;
  background: #fff;
  border: 1px solid #0499d1;
  border-radius: 5px;
}
.msg {
  font-size: 20px;
}
.border {
  // border: 0.5px solid #cccccc;
  border-radius: 5px;
  padding: 3px;
  // margin: 5px;
}
.app-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(12, 24, 51, 0);
}
.app-card-content_item {
  // width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 4px;
  // background: linear-gradient(to top right, #3798da, #1db6fe, #53cdff);
}
// .border-right {
//   border-right: 1px solid rgb(17, 149, 248);
// }
// .border-bottom {
//   border-bottom: 1px solid rgb(17, 149, 248);
// }
.remark {
  font-size: 8px;
  color: #068cc1;
  text-align: left;
  position: absolute;
  bottom: 0;
  margin: 5px;
  width: 15%;
  height: 8%;
  display: flex;
  padding: 10px;
}
.tag {
  width: 20%;
  margin: auto;
  height: 16%;
  margin-top: 4px;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 20px;
  display: flex;
  justify-content: center;
}
.beizhu {
  color: #0a84bd;
  font-size: 8px;
  text-align: left;
}
.default_chip {
  font-size: 13px;
}
.serviceList_chips {
  font-size: 18px;
}
.serviceList_chip {
  font-size: 12px;
  text-align: left;
  color: #808080;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.swipes {
  width: 100%;
  height: 50px;
}
.img {
  width: 100px;
  height: 70px;
}
.imgss {
  width: 105px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadings {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
}
.back {
  position: absolute;
  bottom: 10px;
  right: 10px;
  // background: linear-gradient(to top right, #3798da, #1db6fe, #53cdff);
  border-radius: 5px;
  width: 15px;
  height: 50px;
  font-size: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.no {
  font-size: 20px;
}
.imgs {
  width: 10px;
  height: 10px;
  margin-right: 3px;
}
.zero {
  color: #4a9cbd;
  font-size: 80px;
  width: 100%;
  height: 200px;
  line-height: 200px;
}
.titles {
  font-size: 80px;
  width: 100%;
  height: 240px;
  color: #ffd900;
  line-height: 240px;
}
.date {
  font-size: 6px;
  color: #fff;
  position: absolute;
  top: 25px;
  right: 32px;
}
.popup {
  width: 250px;
  height: 150px;
  // margin: 20px;
  padding: 20px;
  color: #40c5ff;
  background: rgba(7, 11, 23, 0.4);
}
.popuptitle {
  font-size: 20px;
}
.popupfooter {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 80%;
}
.down {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #808080;
  font-size: 8px;
}
.spancolor {
  color: #fff;
}
.anniu {
  color: #7ce7fd;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.annius {
  width: 120px;
  height: 60px;
  margin-left: 5px;
  line-height: 60px;
  text-align: center;
  color: #4cc9d9;
  font-size: 25px;
}
.info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 10px;
  width: 25%;
  height: 10px;
}
.device {
  color: #fff;
  font-size: 10px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.tags {
  padding: 15px;
}
.entershow {
  color: #fff;
  font-size: 15px;
  padding: 10px;
}
.entershowtitle {
  font-size: 15px;
}
.outshow {
  color: #fff;
  font-size: 20px;
  padding: 10px;
}
.key {
  z-index: 9999;
  width: 50%;
  right: 0;
  position: absolute;
  left: auto;
  // bottom: 10%;
  border-radius: 25px;
  padding: 0;
  color: black;
}
</style>
