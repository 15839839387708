<template>
  <Hotel />
</template>

<script>
import Hotel from "../../../components/hotel_hfrd.vue";

export default {
  name: "hotel_hfrd",
  components: {
    Hotel,
  },
};
</script>